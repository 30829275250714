<template>
  <div class="world_box main">
    <Map></Map>
    <div v-if="$route.query.cat == 1">
      <div class="tips1">
        <div class="container content">
          <div class="title_">ECI Brand Ambassador:</div>
          <div class="text_">
            An individual representing ECI, share ECI value, promoting ECI
            awards in their country
          </div>
        </div>
      </div>
      <div class="tips2">
        <div class="container content">
          <div class="title_">Requirements</div>
          <div class="text_">
            Background in Innovation on business, marketing, technology, and
            Art, etc., <br />or participate in ECI jury or won ECI prize, or
            influential figure locally
          </div>
        </div>
      </div>
      <div class="tips3">
        <div class="container content">
          <div class="text_">
            <div class="t1">Responsibility</div>
            1.Representing IECIA to promote ECI awards<br />
            2.Refer judges and refer chapter (distributors) association
            /agency<br />
            3. Promoting 4 levels Membership<br />
            4. Looking for Sponsorship<br />
            5. Collect cases to attend awards competition<br /><br />
            <div class="t1">Accomplishment</div>

            1.Cases: More than 10 cases a year with discount 25% off <br />
            2.Jury/Experts: 10 experts a year (to be in jury or teaching in ECI
            academy)<br />
            3.Membership: Promoting 4 levels Membership<br />
            4.Webinars/classes : Income profit sharing<br />
            Sponsorship: Finding at least 2 sponsorship first year and
            accumulate each year<br /><br />
            <div class="t1">Term of office</div>
            3 years (renew after review)<br />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="tips1">
        <div class="container content">
          <div class="title_">Country Chapter：</div>
          <div class="text_">
            A company or an association representing ECI, share ECI value,
            promoting and organizing ECI awards in their country
          </div>
        </div>
      </div>
      <div class="tips2">
        <div class="container content">
          <div class="title_">Requirements</div>
          <div class="text_">
            A team with background in Innovation on business, marketing,
            technology, and art etc., <br />or participate in ECI jury or won
            ECI prize, or influential.
          </div>
        </div>
      </div>
      <div class="tips3">
        <div class="container content">
          <div class="text_">
            <div class="t1">Responsibility</div>
            1.Representing IECIA to promote ECI awards<br />
            2.Refer judges and refer chapter (distributors) association
            /agency<br />
            3.Promoting 4 levels Membership<br />
            4.Looking for Sponsorship<br />
            5.Collect cases to attend awards competition<br />
            6.Organizing offline monthly galas in their country<br />
            7.Organizing and promoting Webinars of all countries through their
            online and offline channels.<br /><br />
            <div class="t1">Accomplishment</div>

            1.Cases: more than 20 cases a year with discount 25% off
            <br />2.Experts: 20 experts a year (to be in jury or teaching in ECI
            academy) <br />3.Membership: Promoting 4 levels Membership
            <br />4.Webinars: Income profit sharing <br />5.Sponsorship: Finding
            at least 2 sponsorship first year and accumulate each year
            <br />6.Organizing offline monthly galas in their country ( fee
            partially supported by ECI organizing commitee of Europe)
            <br />7.Organizing and promoting Webinars of all countries through
            their online and offline channels. (fee partially supported by ECI
            organizing commitee of Europe) <br />8.Chapter needs tobe autorized
            by ECI and pay a licensing fee<br /><br />
            <div class="t1">Term of office</div>
            3 years (renew after review)<br />
          </div>
        </div>
      </div>
    </div>
    <div class="tips4">
      <div class="container content">
        <div class="title_">Want to be our ECI {{ form_title }}</div>
        <div class="part_form">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item
              label="First Name:"
              prop="first_name"
              label-width="150px"
            >
              <el-input v-model="ruleForm.first_name"></el-input>
            </el-form-item>
            <el-form-item
              label="Last Name:"
              prop="last_name"
              label-width="150px"
            >
              <el-input v-model="ruleForm.last_name"></el-input>
            </el-form-item>
            <el-form-item label="Emai:" prop="email" label-width="150px">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item
              label="Company:"
              prop="company_name"
              label-width="150px"
            >
              <el-input v-model="ruleForm.company_name"></el-input>
            </el-form-item>
            <el-form-item label="Country:" prop="country" label-width="150px">
              <el-input v-model="ruleForm.country"></el-input>
            </el-form-item>

            <el-form-item
              label="Your Message:"
              prop="message"
              class="textarea"
              label-width="150px"
            >
              <el-input type="textarea" v-model="ruleForm.message"></el-input>
            </el-form-item>
            <div class="button" @click="submitForm('ruleForm')">SUBMIT</div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AMBASSADOR, CHAPTER } from '@/api/index.js';
import Map from '@/components/Map.vue';
export default {
  name: 'World',
  components: { Map },
  data() {
    return {
      cat: this.$route.query.cat || '1',
      form_title: 'Brand Ambassador',
      ruleForm: {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        country: '',
        message: '',
      },
      rules: {
        first_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        last_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        email: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        company_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        country: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        message: [{ required: true, message: 'Please enter', trigger: 'blur' }],
      },
    };
  },
  created() {
    if (this.$route.query.cat == 1) {
      this.form_title = 'Brand Ambassador';
    } else {
      this.form_title = 'Country chapter';
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.$route.query.cat == 1) {
            AMBASSADOR(this.ruleForm).then(res => {
              console.log(res);
              if (res.errcode == 0) {
                this.$message({
                  type: 'success',
                  message: 'success',
                });
                this.ruleForm = {
                  first_name: '',
                  last_name: '',
                  email: '',
                  company_name: '',
                  country: '',
                  message: '',
                };
              } else {
                this.$message({
                  type: 'warning',
                  message: res.errmsg,
                });
              }
            });
          } else {
            CHAPTER(this.ruleForm).then(res => {
              console.log(res);
              if (res.errcode == 0) {
                this.$message({
                  type: 'success',
                  message: 'success',
                });
                this.ruleForm = {
                  first_name: '',
                  last_name: '',
                  email: '',
                  company_name: '',
                  country: '',
                  message: '',
                };
              } else {
                this.$message({
                  type: 'warning',
                  message: res.errmsg,
                });
              }
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.world_box {
  .tips2 {
    background-color: #f4f6f8;
  }

  .title_ {
    text-align: center;
    margin-bottom: 20px;
  }
  .text_ {
    text-align: center;
    padding-bottom: 50px;
  }
  .tips3 {
    text-align: center;
    .t1 {
      font-weight: bold;
    }
  }
  .tips4 {
    .title_ {
      span {
        color: $blue;
      }
    }
    .part_form {
      width: 480px;
      margin: 0 auto;
    }
  }
}
@media (max-width: 1000px) {
  .world_box {
    .tips4 {
      .part_form {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}
</style>
